import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChartNetwork } from "@fortawesome/pro-regular-svg-icons";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Card from "../components/card";
import ContentSvg from "../images/undraw/undraw_content_vbqo.svg";
import LogoCloud from "../components/LogoCloud";

const CustomCms = () => {
  return (
    <Layout>
      {(linkClicked, cardVisible) => (
        <>
          <SEO keywords={[]} title="Custom Content Management" />
          <Card
            visible={cardVisible}
            header="Custom Content Management"
            previousUrl="/custom-distributed-multimedia-apps"
            nextUrl="/audience-interactions"
            nextLabel="Audience Interactions"
          >
            <p className="text-base leading-6 text-gray-700 text-left mb-4 sm:mb-5">
              Off-the-shelf content management solutions seldom provide support
              for custom client requirements out of the box. Expanding such
              systems with plugins inevitably leads to upgrade problems and
              eventually total paralysis.
            </p>
            <p className="text-base leading-6 text-gray-700 text-left mb-4 sm:mb-5">
              Remedy can be provided by either using
            </p>
            <ul className="text-base leading-6 text-gray-700 text-left mb-4 sm:mb-5 list-disc list-inside">
              <li>
                Headless CMS solutions like{" "}
                <a
                  className="text-magenta-900"
                  href="https://www.contentful.com/"
                >
                  Contentful
                </a>{" "}
                or{" "}
                <a className="text-magenta-900" href="https://www.sanity.io/">
                  Sanity
                </a>
              </li>
              <li>a completely customized content management backend</li>
            </ul>
            <p className="text-base leading-6 text-gray-700 text-left mb-4 sm:mb-5">
              Which alternative best suits your needs depends on content
              requirements (e.g. formats, internationalization, e-commerce
              readiness) as well as time constraints.
            </p>

            <div className="grid gap-6 grid-cols-1">
              <figure className="w-full">
                <img alt="content" src={ContentSvg} />
              </figure>
            </div>
          </Card>
          <LogoCloud
            logos={[
              {
                name: "kreativfarm",
                url: "http://kreativ.farm/",
              },
              { name: "zone", url: "https://zonemedia.at/" },
            ]}
          />
        </>
      )}
    </Layout>
  );
};

export default CustomCms;
